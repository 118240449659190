import React, { useEffect, useState } from 'react';
import './Sidebar.css'
import { Link } from 'react-router-dom';
import SidebarChannel from './SidebarChannel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

// const axios = require('axios').default;

function Sidebar({channels, selectedChannel, setSelectedChannel, setUser, user, setShowMenu}) {
    let [expandIcons, setExpandIcons] = useState([true, true])

    function sidebarHeaderExpandIcon() {
      expandIcons[1]?(
        setExpandIcons(expandIcons = [expandIcons[0], false])
      ):(
        setExpandIcons(expandIcons = [expandIcons[0], true])
      )
    }

    function uploadNewFile() {
      setSelectedChannel(selectedChannel = "")
    }

    return (
      <div className="sidebar">      
        <div className="sidebar__top" onClick={uploadNewFile}>
          <h1>New Chat</h1>
          <AddIcon onClick={uploadNewFile} className="sidebar__addChannel"/>
        </div>

        {expandIcons[0]?(
          <div className="sidebar__channels">
            {expandIcons[1]?(
              <div className="sidebar__channelsList">
                {channels.map((channel) => ( 
                  <SidebarChannel id='{channel.id}' channelName={channel.channelName} selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel} setShowMenu={setShowMenu}/>
                ))}
              </div>  
            ):(<></>)}
          </div>
        ):(<></>)}
        

        <div className="sidebar__bottom">
            <div className="sidebar__profile">
              <AccountCircleIcon fontSize="large" cursor="pointer"/>
              <div className="sidebar__profileInfo">
                <h3>@{user[0].split('@')[0]}</h3>
              </div>
              <LogoutIcon className="chat__logout" onClick={() => {
                  setUser([])
                  localStorage.clear()
                }}/>
            </div>
          </div>
      </div>
    )
}

export default Sidebar