import React from 'react'
import "./SidebarChannel.css"

function SidebarChannel({ id, channelName, selectedChannel, setSelectedChannel, setShowMenu }) {
  
  let icon = channelName.split(".")[channelName.split(".").length - 1]

  return (
    <div className="sidebarChannel" onClick={() => {
      setSelectedChannel(selectedChannel = channelName)
      setShowMenu(false)
    }}>

      <h4>
        <span className="sidebarChannel__hash">
          <img src={"/" + icon + '.png'} alt="image" width="35" height="35"/>
        </span>
        {channelName}
      </h4>
    </div>
  )
}

export default SidebarChannel