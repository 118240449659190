import React, { useEffect, useState } from 'react';
import UserSidebar from './UserSidebar';
import Sidebar from './Sidebar';
import SidebarMobile from './SidebarMobile';
import Chat from './Chat';
import ChatMobile from './ChatMobile';
import Login from './Login';
import './Dashboard.css';

import prox from '../prox.json'
const axios = require('axios').default;

function Dashboard() {

  let [channels, setChannels] = useState([])
  let [selectedChannel, setSelectedChannel] = useState("")
  let [user, setUser] = useState([])
  let [serverInfo, setServerInfo] = useState([])
  let [userlist, setUserList] = useState([])
  let [showMenu, setShowMenu] = useState(true)

  setTimeout(() => {
    new Promise(() => {
      if (localStorage.getItem("user") && localStorage.getItem("pass")) {
        const getDataJson = {user: localStorage.getItem("user"), password: localStorage.getItem("pass")}
        axios.post(prox.proxy + "/getData", getDataJson).then((result) => {
          const tempArr = []
          result.data.data.map((content) => (
            tempArr.push(content)
          ))
          
          setChannels(tempArr)
        })
      } else {}
    })
  }, 5000);

  // gets server info like server name
  useEffect(() => {
    if (localStorage.getItem("user") && localStorage.getItem("pass")) {
      const getDataJson = {user: localStorage.getItem("user"), password: localStorage.getItem("pass")}
      axios.post(prox.proxy + "/getData", getDataJson).then((result) => {
      // axios.post(prox.proxy + "/getData").then((result) => {
        // updates user list
        
        // pushes data to a temporary array
        const tempArr = []
        console.log(result.data.data)
        result.data.data.map((content) => (
          tempArr.push(content)
        ))
        
        // updates the setChannels state, which is responsible for storing all the server data like messages, channels, etc.
        setChannels(tempArr)
      })
    } else {}
    
  }, [])

  return (
    (user.length===0)?(
      <Login setUser={setUser}/>
    ):(
      <>
        
        <div className="app">
          <Sidebar 
            showMenu={showMenu} setShowMenu={setShowMenu}
            channels={channels} setChannels={setChannels} 
            selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
            setUser={setUser} user={user}
            serverInfo={serverInfo}
          />
          
          <Chat 
            showMenu={showMenu} setShowMenu={setShowMenu}
            selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
            channels={channels} setChannels={setChannels} 
            user={user} 
          />
        </div>

        <div className="appMobile">
          <SidebarMobile 
            // updateData = {updateData}
            showMenu={showMenu} setShowMenu={setShowMenu}
            channels={channels} setChannels={setChannels} 
            selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
            setUser={setUser} user={user}
            serverInfo={serverInfo}
          />
          
          <ChatMobile 
            showMenu={showMenu} setShowMenu={setShowMenu}
            selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
            channels={channels} setChannels={setChannels} 
            user={user} 
          />
        </div>

      </>
    )
  );
}

export default Dashboard;
