import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Login.css'
import hand from "../components/header.png"

import axios from 'axios';
import prox from '../prox.json'

// function Login({ setUser }) {
function Login({ setUser }) {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    let [videoLoaded, setVideoLoaded] = useState(false);

    useEffect(() => {
        // checks if uasername and password exists on the browser
        if (localStorage.getItem("user") && localStorage.getItem("pass")) {
            let username = localStorage.getItem("user")
            let password = localStorage.getItem("pass")

            // if username & password exists, then we check if they are still valid
            axios.post(prox.proxy + '/login', { user: username, password: password }).then((result) => {
                // if the username and password are valid, then we updates the setUser() state
                setUser([result.data.user, result.data.publicKey, result.data.img])
            }).catch((err) => {
                // if the username and password are not valid, then we clear the local storage and ask the user to login again
                localStorage.clear()
                alert('An error Occureed please login again')
            })
        }
    }, [])
    
    let submittedCreds = false
    // handles the user login
    function login() {
        // checks if username & password are not empty
        if (username.length != 0 && password.length != 0 && !submittedCreds) {
            // logs in with the username and password provided, and if the login is successful then it stores the credentials locally on the browser
            axios.post(prox.proxy + '/login', { user: username, password: password }).then((result) => {
                if (result.data.user && result.data.publicKey && result.data.img) {
                    localStorage.clear()
                    localStorage.setItem('user', username);
                    localStorage.setItem('pass', password);
                    // updates the user state
                    setUser([result.data.user, result.data.publicKey, result.data.img])
                    submittedCreds = true
                
                    setUser([result.data.user, result.data.publicKey, result.data.img])
                } else {
                  alert('Incorrect username or password')
                }
        }).catch((err) =>{
                // if the login fails, then we clear the local storage and ask the user to login again
                localStorage.clear()
                console.log(err);
            })
        } else {}
    }

    // background video, that plays in the background
    const video = "videoplayback.webm"

    return (
        <div>
            <div class="vid-container">
                <video src={require('./video-3.mp4')} autoPlay loop muted onLoadedData={()=>{setVideoLoaded(true)}}/>
                {(videoLoaded)?(<></>):(<img className="mainImageHeader" src="video-3.jpg" />)}

                <div class="inner-container">
                    <div className="heading">
                    <Link to='/'>
                        <img src={hand} alt="hand" className="handPng" />
                    </Link>
                    </div>
                    
                    
                    
                    <form onSubmit={(e) => {e.preventDefault()}}>
                        <div class="box">
                            <h1>Login | Sign Up</h1>
                            <input value={username} onChange={(e) => setUsername(e.target.value)} type="email" placeholder="Email"/>
                            <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password"/>
                            <button onClick={login} type="submit"> Login/Sign Up </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default Login