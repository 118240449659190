import React from 'react'
import ChatHeader from './ChatHeader'
import Message from "./Message";
import './Chat.css'
import { useRef } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import GifIcon from '@mui/icons-material/Gif';

import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import Stack from '@mui/joy/Stack';
import Button0 from '@mui/joy/Button';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { useState, useEffect } from "react";

import prox from '../prox.json'
const axios = require('axios').default;


function Chat({
        selectedChannel, setSelectedChannel,
        channels, setChannels,
        user
    }) {
    let icon = selectedChannel.split(".")[selectedChannel.split(".").length - 1]

    const [input, setInput] = useState("")
    let [messages, setMessages] = useState([])
    let [loaded, setLoaded] = useState([false])


	let [uploadFile, setUploadFile] = useState([])
	let [uploadStatus, setUploadStatus] = useState([false])
    
    const messageRef = useRef()
    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView({
                block: 'end',
                inline: 'nearest'
            })
        }
    }, [messages])
    
    // // updates the messages depeding on the channel selected
    useEffect(() => {
        const timeoutID = window.setTimeout(() => {
            // loops through all the channel names
            for (let index = 0; index < channels.length; index++) {
                // checks if channel name is equal to the selected channel
                if (channels[index].channelName === selectedChannel) {
                    // if the channel name and selected channel is same, then it updates the setMessages() state
                    setMessages(channels[index].data)
                    setLoaded([channels[index].loaded])
                }
            }
        }, 100);
    
        return () => window.clearTimeout(timeoutID );
    })

    // gets the server data
    function updateData() {
        new Promise(() => {
            if (localStorage.getItem("user") && localStorage.getItem("pass")) {
                const getDataJson = {user: localStorage.getItem("user"), password: localStorage.getItem("pass")}
                axios.post(prox.proxy + "/getData", getDataJson).then((result) => {
                  const tempArr = []
                  result.data.data.map((content) => (
                    tempArr.push(content)
                  ))
                  
                  setChannels(tempArr)
                })
            } else {}
        })
    }


    // gets executed when a new message is sent
    function handleAddMessage() {
        for (let index = 0; index < channels.length; index++) {
            if (channels[index].channelName === selectedChannel) {
                const getDataJson = {user: localStorage.getItem("user"), password: localStorage.getItem("pass")}
                
                if (selectedChannel && loaded[0] && input) {
                    axios.post(prox.proxy + '/newMessage', {channel: selectedChannel, msg: input, cedentials: getDataJson}).then((result0) => {
                        if (result0.data.status === 'ok') {
                            setInput("")
                            updateData()
    
                            axios.post(prox.proxy + '/response', [result0.data.data, getDataJson]).then((result1) => {
                                updateData()
                            })
    
                        }
                    })
                }
                
            }
        }
    }

    // for upload file button click
    function uploadFileFunc() {
        const getDataJson = {user: localStorage.getItem("user"), password: localStorage.getItem("pass")}

        const json = JSON.stringify(getDataJson);
        const data = new Blob([json], {
            type: 'application/json'
        });

        var formData = new FormData();
		formData.append("data", data);
		formData.append("file", uploadFile);

        console.log(formData);

        
        let extension = uploadFile.name.split(".")[uploadFile.name.split(".").length - 1]
        let extensionSupported = false
        if (extension == 'txt') {
            extensionSupported = true
        } 
        if (extension == 'pdf') {
            extensionSupported = true
        }
        if (extension == 'html') {
            // extensionSupported = true
        }
        if (extension == 'pptx') {
            extensionSupported = true
        }
        if (extension == 'docx') {
            extensionSupported = true
        }


        let validSize = false
        if (uploadFile.size < 5000001) {
            validSize = true
        }

        if (validSize === true) {
            if (extensionSupported === true) {
                axios.post(prox.proxy + '/upload', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                      'enctype': "multipart/form-data"
                    }
                }).then((result) => {

                    if (localStorage.getItem("user") && localStorage.getItem("pass")) {
                        const getDataJson = {user: localStorage.getItem("user"), password: localStorage.getItem("pass")}
                        axios.post(prox.proxy + "/getData", getDataJson).then((result0) => {

                            // pushes data to a temporary array
                            const tempArr = []
                            result0.data.data.map((content) => (
                                tempArr.push(content)
                            ))
                            
                            if (result.data.status === 'upload complete') {
                                setUploadFile([])
                                setChannels(tempArr)
                                setUploadStatus([false])
                                setSelectedChannel(selectedChannel = uploadFile.name)
                                updateData()
                            }
                        })
                      } else {}
                })


            } else {
                alert('File type not supported')
                setUploadFile([])
                setUploadStatus([false])
                updateData()
            }
        } else {
            alert(uploadFile.name + " is too large (" + parseInt(uploadFile.size)/1000000 + " Megabytes)." + " Please upload files smaller than 5 megabytes.");
            setUploadFile([])
            setUploadStatus([false])
            updateData()
        }
    }
    function handleFile(event) {
        setUploadStatus(true)
        setUploadFile(event.target.files[0])
	}
    const inputFile = useRef(null) 
    const onButtonClick = () => {
        inputFile.current.click();
    };
    try {
        if (uploadFile.name) {
            if (uploadFile.name != "") {
                uploadFileFunc()
            }
        }
    } catch {}
    

    return (
        <div className="chat">
            {((selectedChannel!=''))?(<ChatHeader channelName={selectedChannel} />):(<></>)}

            <div className="chat__messages">
                {(selectedChannel!='')?((loaded[0])?(
                    <div ref={messageRef}>
                        {messages.map((msg) => (<Message user={'You'} msg={msg.msg} response={msg.response}/>))}
                    </div>
                ):(
                    <div className="chat__messagesNone">
                        <h1 align="center">Learning new things and Building new concepts</h1>
                        <span class="loader"/>
                        <div align="center" className="chat__textBottom">
                            {/* Your file has been uploaded, please wait while I learn. <br/>This can sometimes take a while, in the meantime try and refresh the page to check the status of your file */}
                            {/* Your file has been uploaded. Please wait while we process it. <br/>The processing of your file may take some time. You will receive an email notification once it's complete. */}
                            Your file has been uploaded. Please wait while we process it.<br/>This may take some time. Please Wait or Try refeshing the page.
                        </div>
                    </div>
                )
            ):(
                    <>
                        <div className="chat__messagesNone0">
                            <h1 align="center">Upload to start talking with your Files!</h1>
                            <p align="center">Supported File Types:</p>
                            <ul>
                                <li>Text Documents</li>
                                <li>PDF Documents</li>
                                <li>Microsoft PowerPoint</li>
                                <li>Word Documents</li>
                            </ul>
                            
                            <form onSubmit={(e) => {e.preventDefault()}}>
                            {(uploadStatus[0] === false)?(
                                <>
                                    <input type="file" id="file" name="file" ref={inputFile} onChange={handleFile} hidden/>
                                    <Button size="large" type="submit" variant="outlined" startIcon={<UploadIcon/>} onClick={onButtonClick}>
                                        Upload File
                                    </Button>
                                </>
                            ):(
                                <>
                                    <h5>Please don't refresh!</h5>
                                    <Stack spacing={2} direction="row">
                                        <Button0 loading loadingPosition="start" variant="outlined">
                                            Uploading File
                                        </Button0>
                                    </Stack>
                                </>
                            )}
                            </form>		
                        </div>
                    </>
                )}
            </div>
            
            <div className="chat__input">
                {/* <AddCircleIcon fontSize="large"/> */}
                {(selectedChannel)?(
                    <img src={"/" + icon + '.png'} alt="image" width="40" height="40"/>
                ):(<></>)}
                <form onSubmit={(e) => {e.preventDefault()}}>
                    <input className="chat__mainTextFeild" disabled={!selectedChannel || !loaded[0]} value={input} onChange={(e) => setInput(e.target.value)} placeholder={(selectedChannel)?('# Message'):('')} />
                    <button className="chat__inputButton" type="submit" onClick={handleAddMessage}>
                        Send Message
                    </button>
                </form>

                <div className="chat__inputIcons">
                    <ArrowUpwardIcon fontSize="large" disabled={!selectedChannel || !loaded[0]} onClick={handleAddMessage}/>
                </div>
            </div>
        </div>
    )
}

export default Chat