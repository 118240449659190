import React, { useEffect, useState } from 'react';
import './SidebarMobile.css'
import { Link } from 'react-router-dom';
import SidebarChannel from './SidebarChannel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

// const axios = require('axios').default;

function Sidebar({channels, selectedChannel, setSelectedChannel, setUser, user, showMenu, setShowMenu}) {
    let [expandIcons, setExpandIcons] = useState([true, true])

    function sidebarHeaderExpandIcon() {
      expandIcons[1]?(
        setExpandIcons(expandIcons = [expandIcons[0], false])
      ):(
        setExpandIcons(expandIcons = [expandIcons[0], true])
      )
    }

    function uploadNewFile() {
      setSelectedChannel(selectedChannel = "")
      setShowMenu(false)
    }

    return (
      (showMenu)?(
        <div className="sidebarMobile">      
          <div className="sidebarMobile__top" onClick={uploadNewFile}>
            <h1>New Chat</h1>
            <AddIcon onClick={uploadNewFile} className="sidebarMobile__addChannel"/>
          </div>

          {expandIcons[0]?(
            <div className="sidebarMobile__channels">
              {expandIcons[1]?(
                <div className="sidebarMobile__channelsList">
                  {channels.map((channel) => ( 
                    <SidebarChannel id='{channel.id}' channelName={channel.channelName} selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel} setShowMenu={setShowMenu}/>
                  ))}
                </div>  
              ):(<></>)}
            </div>
          ):(<></>)}
          

          <div className="sidebarMobile__bottom">
              <div className="sidebarMobile__profile">
                <AccountCircleIcon fontSize="large" cursor="pointer"/>
                <div className="sidebarMobile__profileInfo">
                  <h3>@{user[0].split('@')[0]}</h3>
                </div>
                <LogoutIcon className="chat__logout" onClick={() => {
                    setUser([])
                    localStorage.clear()
                  }}/>
              </div>
            </div>
        </div>
      ):(<></>)
    )
}

export default Sidebar