import React from 'react'
import './Message.css'

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/joy/Avatar';
import LoadingButton from '@mui/joy/Button';
import Box from '@mui/joy/Box';

function Message({ user, date, msg, response }) {
  return (
    <div>
        <div className="message">
            <AccountCircleIcon fontSize="large" />
            {/* <Avatar>{user.split(user[2])[0].toUpperCase()}</Avatar> */}
            {/* <img src={"/user.png"} alt="image" width="35" height="35"/> */}

            <div className="message__info">
                {/* <h4>{user} */}
                <h4>{user}
                    <span className="message__timestamp">{date}</span>
                </h4>

                <p>{msg}</p>
            </div>
        </div>
        <div className="message_response">
            {/* <AccountCircleIcon fontSize="large" /> */}
            <img src="/robot.png" alt="image" width="43" height="43"/>
            <div className="message__info_response">
                {(response.length>0)?(
                    <p>{response}</p>
                ):(
                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                        <p>
                        Generating Response
                        <LoadingButton loading variant="plain">
                            Plain
                        </LoadingButton>
                        </p>
                    </Box>
                )}
            </div>
        </div>
    </div>
  )
}

export default Message